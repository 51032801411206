import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountAmountLeft, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn, SearchCrteateBtn, SearchExcelBtn, SearchInputBtn } from './component/search/search';
import { useNavigate } from 'react-router-dom';

function Agency() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)
    const [loading, setLoading] = useState(true)

    const [createModal, setCreateModal] = useState(false)
    const [updateModal, setUpdateModal] = useState(false)

    const [message, setMessage] = useState("")
    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [clickData, setClickData] = useState([])
    const [reload, setReload] = useState(false)

    // 검색조건 포맷
    const select3 = ["에이전시명", "대표자명", "대표자 전화번호"]

    const [selectedValue2, setSelectedValue2] = useState("에이전시명");

    const handleSelectChange2 = (event) => {
        const value = event.target.value;
        setSelectedValue2(value);
    };

    // 검색어 포맷
    const [selectedText4, setSelectText4] = useState("")

    const handleSelectTextChange4 = (event) => {
        const value = event.target.value;
        setSelectText4(value);
    };

    useEffect(() => {
        dispatch(change_Current_Location("에이전시"))
        dispatch(changeHeaderTitle({
            mainTitle: "매장관리",
            subTitle: "에이전시"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const handleChange = (event) => {
        setMaxFirstPage(Number(event.target.value));
    };

    useEffect(() => {
        ListData()
    }, [page, maxFirstPage, reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/sales/list", {
            withCredentials: true,
            params: {
                page: page,
                limit: maxFirstPage,
                searchCategory: selectedValue2 == "에이전시명" ? 1 : selectedValue2 == "대표자명" ? 2 : 3,
                searchValue: selectedText4,
                businessType: 2,
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        // { "no": 1, "text": "NO", "width": "70px", "textAlign": "left" },
        { "no": 2, "text": "MCODE", "width": "100px", "textAlign": "center" },
        { "no": 3, "text": "에이전시명", "width": "150px", "textAlign": "left" },
        // { "no": 4, "text": "구분", "width": "120px", "textAlign": "center" },
        { "no": 5, "text": "대표자명", "width": "100px", "textAlign": "center" },
        { "no": 6, "text": "전화번호", "width": "150px", "textAlign": "center" },
        { "no": 7, "text": "은행", "width": "120px", "textAlign": "center" },
        { "no": 8, "text": "계좌번호", "width": "180px", "textAlign": "left" },
        { "no": 9, "text": "주소", "width": "500px", "textAlign": "left" },
        { "no": 10, "text": "등록날짜", "width": "180px", "textAlign": "center" }
    ];

    useEffect(() => {
        let tableBodyData = [];
        if (listData) {
            let list = listData?.items ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                let date = data.createdAt.split('T')
                let date1 = date[1].split('.')

                tableBodyData.push([
                    // { "no": 1, "text": maxFirstPage * (page - 1) + i + 1, "width": "70px", "textAlign": "left" },
                    { "no": 2, "text": data.id, "width": "100px", "textAlign": "center" },
                    { "no": 3, "text": (<div className='pointer' onClick={() => { setClickData(data); setUpdateModal(true) }}>{data.name}</div>), "width": "150px", "textAlign": "left" },
                    // { "no": 4, "text": "에이전시", "width": "120px", "textAlign": "center" },
                    { "no": 5, "text": data.representativeName, "width": "100px", "textAlign": "left" },
                    { "no": 6, "text": data.phone1, "width": "150px", "textAlign": "center" },
                    { "no": 7, "text": data.bankName, "width": "120px", "textAlign": "center" },
                    { "no": 8, "text": data.accountNumber, "width": "180px", "textAlign": "left" },
                    { "no": 9, "text": data.address, "width": "500px", "textAlign": "left" },
                    { "no": 10, "text": `${date[0]} ${date1[0]}`, "width": "180px", "textAlign": "center" }
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData])

    const excelBtn = async () => {
        await axios.get(process.env.REACT_APP_API + "/sales/list/excel", {
            withCredentials: true,
            params: {
                businessType: 2
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                excelData(tmp?.data ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const excelData = (exData) => {
        let arr = []
        if (exData) {
            for (let i = 0; i < exData.length; i++) {
                let t = exData[i]

                let date = t.createdAt.split('T')
                let date1 = date[1].split('.')

                arr.push({
                    "NO": i + 1,
                    "MCODE": t.id,
                    "에이전시명": t.name,
                    "구분": "에이전시",
                    "대표자명": t.representativeName,
                    "전화번호": t.phone1,
                    "은행": t.bankName,
                    "계좌번호": t.accountNumber,
                    "주소": t.address,
                    "등록날짜": `${date[0]} ${date1[0]}`,
                })
            }
        }
        Excel(arr)
    }

    // 더보기 버튼
    const [moreBtn, setMoreBtn] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640) setMoreBtn(true)
            else setMoreBtn(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            {createModal ? <CreateInfo setModal={setCreateModal} setMessage={setMessage} setReload={setReload} /> : ""}
            {updateModal ? <UpdateInfo setModal={setUpdateModal} setMessage={setMessage} clickData={clickData} setReload={setReload} /> : ""}
            <MessageModal message={message} />
            <div className="form_filter intro-y">
                <form id="tabulator-html-filter-form">
                    {<SearchSelect type={"검색조건"} selectData={select3} handleSelectChange={handleSelectChange2} selectedValue={selectedValue2} />}

                    {<SearchInputBtn type={"검색어"} selectedText={selectedText4} handleSelectTextChange={handleSelectTextChange4} onClick={() => setReload(e => !e)} />}

                    <div class="button_wrap separate">
                        <SearchCrteateBtn type={"에이전시 등록"} onClick={() => setCreateModal(true)} />
                        <SearchExcelBtn type={"엑셀 다운로드"} onClick={() => excelBtn()} />
                    </div>
                </form>
                <div className="more_wrap">
                    <button type="button" onClick={() => setMoreBtn(e => !e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                    </button>
                </div>
            </div>
            <div className="intro-y box p-5 mt-5">
                <div className="tabulator_sorting_wrap">
                    <CountAmountLeft tableBodyData={listData?.total ?? 0} />
                    <CountSelect onChange={handleChange} />
                </div>

                <List tableHeaderData={tableHeaderData} tableBodyData={listData?.total ?? 0} filter_tableBodyData={combineData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />
            </div>
        </div>
    )
}

function CreateInfo({ setModal, setMessage, setReload }) {
    const [levelData, setLevelData] = useState([])
    const [formData, setFormData] = useState({
        PARENT_CODE: '',
        BIZ_GUBUN: '',
        NAME: '',
        BIZ_SOC_NO: '',
        CEO: '',
        BIZ_TYPE: '',
        BIZ_EMAIL: '',
        CEO_SOC_NO: '',
        HOLDER_NAME: '',
        BIZ_PHONE: '',
        FEE: '',
        HP: '',
        BIZ_ADDRESS: '',
        MEMO: '',
        BANK_CODE: '',
        BANK_NO: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        LevelList()
    }, [])

    const LevelList = async () => {
        await axios.get(process.env.REACT_APP_API + `/sales/parent-list?level=1`, {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                setLevelData(tmp?.data ?? [])
            }
            else {
                setMessage({ msg: "상위리스트 업로드에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmit = async () => {
        await axios.post(process.env.REACT_APP_API + "/sales", {
            name: formData.NAME,
            parentId: formData.PARENT_CODE,
            businessTypeId: 2,
            representativeName: formData.CEO,
            registrationNumber: formData.CEO_SOC_NO,
            email: formData.BIZ_EMAIL,
            agencyRegistrationNumber: formData.BIZ_SOC_NO,
            phone1: formData.HP,
            phone2: formData.BIZ_PHONE,
            address: formData.BIZ_ADDRESS,
            bankName: formData.BANK_CODE,
            accountNumber: formData.BANK_NO,
            accountHolder: formData.HOLDER_NAME,
            feeRate: formData.FEE,
            memo: formData.MEMO
        }, {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                setReload(e => !e)
                setModal(false)
            }
            else {
                setMessage({ msg: "에이전시 등록에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    return (
        <div id="distributor-modal" className="modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">에이전시 정보</h2>

                        {/* <button type="button" className="btn btn-primary" id="masking_disable" style={{ padding: "5px", marginRight: "20px" }}>입력폼 활성화</button> */}

                        <button type="button" className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                        <div id="data_wrap">
                            <input type="hidden" />
                        </div>
                    </div>
                    <form id="modal-form" className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="PARENT_CODE" className="form-label">영업자</label>
                            <select
                                id="PARENT_CODE"
                                name="PARENT_CODE"
                                className="form-select w-full 2xl:w-full mt-2 sm:mt-0 confirm_select"
                                value={formData.PARENT_CODE}
                                onChange={handleChange}
                            >
                                <option value={0}>상위업체 선택해주세요.</option>
                                {
                                    levelData.map(function (a, i) {
                                        return (
                                            <option value={a.id}>{a.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BIZ_GUBUN" className="form-label">사업자 구분</label>
                            <select
                                id="BIZ_GUBUN"
                                name="BIZ_GUBUN"
                                className="form-select w-full 2xl:w-full mt-2 sm:mt-0 confirm_select"
                                value={formData.BIZ_GUBUN}
                                onChange={handleChange}
                            >
                                <option value="사업자">사업자</option>
                                <option value="비사업자">비사업자</option>
                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="NAME" className="form-label">에이전시명</label>
                            <input
                                id="NAME"
                                name="NAME"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.NAME}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BIZ_SOC_NO" className="form-label">주민번호(사업자번호)</label>
                            <input
                                id="BIZ_SOC_NO"
                                name="BIZ_SOC_NO"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="14"
                                value={formData.BIZ_SOC_NO}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="CEO" className="form-label">대표자</label>
                            <input
                                id="CEO"
                                name="CEO"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.CEO}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BIZ_TYPE" className="form-label">업종</label>
                            <input
                                id="BIZ_TYPE"
                                name="BIZ_TYPE"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="10"
                                value={formData.BIZ_TYPE}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BIZ_EMAIL" className="form-label">이메일</label>
                            <input
                                id="BIZ_EMAIL"
                                name="BIZ_EMAIL"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.BIZ_EMAIL}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="CEO_SOC_NO" className="form-label">예금주 주민번호(사업자)</label>
                            <input
                                id="CEO_SOC_NO"
                                name="CEO_SOC_NO"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.CEO_SOC_NO}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="HOLDER_NAME" className="form-label">예금주</label>
                            <input
                                id="HOLDER_NAME"
                                name="HOLDER_NAME"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.HOLDER_NAME}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BIZ_PHONE" className="form-label">사업자 전화번호</label>
                            <input
                                id="BIZ_PHONE"
                                name="BIZ_PHONE"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="13"
                                value={formData.BIZ_PHONE}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="FEE" className="form-label">수수료율</label>
                            <input
                                id="FEE"
                                name="FEE"
                                type="text"
                                className="form-control"
                                value={formData.FEE}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="HP" className="form-label">대표자 전화번호</label>
                            <input
                                id="HP"
                                name="HP"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.HP}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="BIZ_ADDRESS" className="form-label">주소</label>
                            <input
                                id="BIZ_ADDRESS"
                                name="BIZ_ADDRESS"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.BIZ_ADDRESS}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="MEMO" className="form-label">메모</label>
                            <input
                                id="MEMO"
                                name="MEMO"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.MEMO}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BANK_CODE" className="form-label">은행명</label>
                            <select
                                id="BANK_CODE"
                                name="BANK_CODE"
                                className="form-select w-full 2xl:w-full mt-2 sm:mt-0 confirm_select"
                                value={formData.BANK_CODE}
                                onChange={handleChange}
                            >
                                <option value="001">한국은행</option>
                                <option value="002">산업은행</option>
                                <option value="003">기업은행</option>
                                <option value="004">국민은행</option>
                                {/* 다른 옵션들 추가 */}
                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BANK_NO" className="form-label">계좌번호</label>
                            <input
                                id="BANK_NO"
                                name="BANK_NO"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.BANK_NO}
                                onChange={handleChange}
                            />
                        </div>
                    </form>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary w-20 reg_btn" onClick={() => onSubmit()}>등록</button>
                        <input type="button" className="btn btn-primary w-20 close_btn" onClick={() => setModal(false)} style={{ backgroundColor: "darkred", marginLeft: "10px", border: "none" }} value="닫기" />
                    </div>
                </div>
            </div>
        </div>
    )
}

function UpdateInfo({ setModal, setMessage, clickData, setReload }) {
    const [levelData, setLevelData] = useState([])
    const [formData, setFormData] = useState({
        PARENT_CODE: clickData?.parentId ?? "",
        BIZ_GUBUN: "",
        NAME: clickData?.name ?? "",
        BIZ_SOC_NO: clickData?.agencyRegistrationNumber ?? "",
        CEO: clickData?.representativeName ?? "",
        BIZ_TYPE: "",
        BIZ_EMAIL: clickData?.email ?? "",
        CEO_SOC_NO: clickData?.registrationNumber ?? "",
        HOLDER_NAME: clickData?.accountHolder ?? "",
        BIZ_PHONE: clickData?.phone2 ?? "",
        FEE: clickData?.feeRate ?? "",
        HP: clickData?.phone1 ?? "",
        BIZ_ADDRESS: clickData?.address ?? "",
        MEMO: clickData?.memo ?? "",
        BANK_CODE: clickData?.bankName ?? "",
        BANK_NO: clickData?.accountNumber ?? ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        LevelList()
    }, [])

    const LevelList = async () => {
        await axios.get(process.env.REACT_APP_API + `/sales/parent-list?level=1`, {
            withCredentials: true,
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                setLevelData(tmp?.data ?? [])
            }
            else {
                setMessage({ msg: "상위리스트 업로드에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }
    
    const onSubmit = async () => {
        if (window.confirm("수정하시겠습니까?")) {
            await axios.post(process.env.REACT_APP_API + "/sales/update", {
                id: clickData.id,
                name: formData.NAME,
                parentId: formData.PARENT_CODE,
                businessTypeId: 2,
                representativeName: formData.CEO,
                registrationNumber: formData.CEO_SOC_NO,
                email: formData.BIZ_EMAIL,
                agencyRegistrationNumber: formData.BIZ_SOC_NO,
                phone1: formData.HP,
                phone2: formData.BIZ_PHONE,
                address: formData.BIZ_ADDRESS,
                bankName: formData.BANK_CODE,
                accountNumber: formData.BANK_NO,
                accountHolder: formData.HOLDER_NAME,
                feeRate: formData.FEE,
                memo: formData.MEMO
            }, {
                withCredentials: true,
            }).then((response) => {
                let tmp = response?.data
                if (tmp?.code < 2000) {
                    setReload(e => !e)
                    setModal(false)
                }
                else {
                    setMessage({ msg: "에이전시 수정에 실패했습니다." })
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    return (
        <div id="distributor-modal" className="modal" tabindex="-1" >
            <div className="modal-dialog">
                <div className="modal-content marginTop30 modal_sty">
                    <div className="modal-header">
                        <h2 className="font-medium text-base mr-auto modal_title_sty">에이전시 정보</h2>

                        {/* <button type="button" className="btn btn-primary" id="masking_disable" style={{ padding: "5px", marginRight: "20px" }}>입력폼 활성화</button> */}

                        <button type="button" className="dropdown-toggle btn px-1 py-1 box modal_btn close_btn_sty" aria-expanded="false" data-tw-toggle="dropdown" onClick={() => setModal(false)}>
                            <span className="w-4 h-4 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="plus" className="lucide lucide-plus w-4 h-4" data-lucide="plus">
                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                </svg>
                            </span>
                        </button>
                        <div id="data_wrap">
                            <input type="hidden" />
                        </div>
                    </div>
                    <form id="modal-form" className="modal-body grid grid-cols-12 gap-4 gap-y-3">
                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="PARENT_CODE" className="form-label">영업자</label>
                            <select
                                id="PARENT_CODE"
                                name="PARENT_CODE"
                                className="form-select w-full 2xl:w-full mt-2 sm:mt-0 confirm_select"
                                value={formData.PARENT_CODE}
                                onChange={handleChange}
                            >
                                <option value={0}>상위업체 선택해주세요.</option>
                                {
                                    levelData.map(function (a, i) {
                                        return (
                                            <option value={a.id}>{a.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BIZ_GUBUN" className="form-label">사업자 구분</label>
                            <select
                                id="BIZ_GUBUN"
                                name="BIZ_GUBUN"
                                className="form-select w-full 2xl:w-full mt-2 sm:mt-0 confirm_select"
                                value={formData.BIZ_GUBUN}
                                onChange={handleChange}
                            >
                                <option value="사업자">사업자</option>
                                <option value="비사업자">비사업자</option>
                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="NAME" className="form-label">에이전시명</label>
                            <input
                                id="NAME"
                                name="NAME"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.NAME}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BIZ_SOC_NO" className="form-label">주민번호(사업자번호)</label>
                            <input
                                id="BIZ_SOC_NO"
                                name="BIZ_SOC_NO"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="14"
                                value={formData.BIZ_SOC_NO}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="CEO" className="form-label">대표자</label>
                            <input
                                id="CEO"
                                name="CEO"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.CEO}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BIZ_TYPE" className="form-label">업종</label>
                            <input
                                id="BIZ_TYPE"
                                name="BIZ_TYPE"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="10"
                                value={formData.BIZ_TYPE}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BIZ_EMAIL" className="form-label">이메일</label>
                            <input
                                id="BIZ_EMAIL"
                                name="BIZ_EMAIL"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.BIZ_EMAIL}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="CEO_SOC_NO" className="form-label">예금주 주민번호(사업자)</label>
                            <input
                                id="CEO_SOC_NO"
                                name="CEO_SOC_NO"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.CEO_SOC_NO}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="HOLDER_NAME" className="form-label">예금주</label>
                            <input
                                id="HOLDER_NAME"
                                name="HOLDER_NAME"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.HOLDER_NAME}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BIZ_PHONE" className="form-label">사업자 전화번호</label>
                            <input
                                id="BIZ_PHONE"
                                name="BIZ_PHONE"
                                type="text"
                                className="form-control confirm_input"
                                maxLength="13"
                                value={formData.BIZ_PHONE}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="FEE" className="form-label">수수료율</label>
                            <input
                                id="FEE"
                                name="FEE"
                                type="text"
                                className="form-control"
                                value={formData.FEE}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="HP" className="form-label">대표자 전화번호</label>
                            <input
                                id="HP"
                                name="HP"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.HP}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="BIZ_ADDRESS" className="form-label">주소</label>
                            <input
                                id="BIZ_ADDRESS"
                                name="BIZ_ADDRESS"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.BIZ_ADDRESS}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="MEMO" className="form-label">메모</label>
                            <input
                                id="MEMO"
                                name="MEMO"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.MEMO}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BANK_CODE" className="form-label">은행명</label>
                            <select
                                id="BANK_CODE"
                                name="BANK_CODE"
                                className="form-select w-full 2xl:w-full mt-2 sm:mt-0 confirm_select"
                                value={formData.BANK_CODE}
                                onChange={handleChange}
                            >
                                <option value="001">한국은행</option>
                                <option value="002">산업은행</option>
                                <option value="003">기업은행</option>
                                <option value="004">국민은행</option>
                                {/* 다른 옵션들 추가 */}
                            </select>
                        </div>

                        <div className="col-span-12 sm:col-span-6">
                            <label htmlFor="BANK_NO" className="form-label">계좌번호</label>
                            <input
                                id="BANK_NO"
                                name="BANK_NO"
                                type="text"
                                className="form-control confirm_input"
                                value={formData.BANK_NO}
                                onChange={handleChange}
                            />
                        </div>
                    </form>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary w-20 reg_btn" onClick={() => onSubmit()}>수정</button>
                        <input type="button" className="btn btn-primary w-20 close_btn" onClick={() => setModal(false)} style={{ backgroundColor: "darkred", marginLeft: "10px", border: "none" }} value="닫기" />
                    </div>
                </div>
            </div>
        </div>
    )
}

const Excel = (excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = `에이전시리스트`;

    const ws = XLSX.utils.aoa_to_sheet([
        [
            "NO", "MCODE", "에이전시명", "구분", "대표자명", "전화번호", "은행", "계좌번호", "주소", "등록날짜"
        ]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                [
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.NO },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.MCODE },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.에이전시명 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.구분 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.대표자명 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.전화번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.은행 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.계좌번호 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.주소 },
                    { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.등록날짜 }
                ]

            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 }
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Agency