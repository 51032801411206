import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, SettlementAmount, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Litepicker from 'litepicker';
import 'litepicker/dist/css/litepicker.css';
import { SearchDate, SearchRange, SearchSelect, SearchSelectText, SearchBtn } from './component/search/search';
import { useNavigate } from 'react-router-dom';
import { changeNum } from './common/function';

function Settlements() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)
    const [amount, setAmount] = useState([1000000, 50000])
    const [loading, setLoading] = useState(true)

    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [reload, setReload] = useState(false)
    const [amountResult, setAmountResult] = useState([])

    useEffect(() => {
        dispatch(change_Current_Location("정산내역"))
        dispatch(changeHeaderTitle({
            mainTitle: "정산관리",
            subTitle: "정산내역"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])

    const handleChange = (event) => {
        setMaxFirstPage(Number(event.target.value));
    };

    // 기간 포맷
    const inputRef = useRef(null);
    const pickerRef = useRef(null); // Litepicker 인스턴스를 저장할 ref

    useEffect(() => {
        pickerRef.current = new Litepicker({
            element: inputRef.current,
            lang: "ko",
            singleMode: false, // 날짜 범위 선택
            format: 'YYYY/MM/DD', // 날짜 형식
            numberOfMonths: 2, // 두 달 표시
            numberOfColumns: 2, // 두 열로 나누어 표시
            showWeekNumbers: true, // 주 번호 표시
            dropdowns: {
                minYear: 2020,
                maxYear: 2030,
                months: true,
                years: true,
            },
            setup: (picker) => {
                // 이벤트 리스너 등록
                picker.on('selected', (start, end) => {
                    setPreDate(start.format('YYYY/MM/DD'));
                    setNextDate(end.format('YYYY/MM/DD'));
                });
            }
        });

        return () => {
            pickerRef.current?.destroy(); // 컴포넌트 언마운트 시 파괴
        };
    }, []);

    // 날짜범위 포맷
    const [preDate, setPreDate] = useState("")
    const [nextDate, setNextDate] = useState("")

    // 날짜를 설정하는 함수
    const setDateRange = (startDate, endDate) => {
        setPreDate(startDate)
        setNextDate(endDate)
        pickerRef.current?.setDateRange(startDate, endDate);
        onSubmitSearch()
    };

    const onSubmitSearch = () => {
        setPage(1)
        setReload(e => !e)
    }

    // 영업자 포맷
    const select1 = ["전체", "총판", "에이전시", "대리점"]

    const [selectedValue, setSelectedValue] = useState("전체");
    const [selectedText, setSelectText] = useState("")

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
    };

    const handleSelectTextChange = (event) => {
        const value = event.target.value;
        setSelectText(value);
    };

    // 검색구분 포맷
    const select4 = ["매장명", "TID"]

    const [selectedValue3, setSelectedValue3] = useState("매장명");
    const [selectedText3, setSelectText3] = useState("")

    const handleSelectChange3 = (event) => {
        const value = event.target.value;
        setSelectedValue3(value);
    };

    const handleSelectTextChange3 = (event) => {
        const value = event.target.value;
        setSelectText3(value);
    };

    useEffect(() => {
        ListData()
    }, [page, maxFirstPage, reload])

    const ListData = async () => {
        await axios.get(process.env.REACT_APP_API + "/settlement/list", {
            withCredentials: true,
            params: {
                page: page,
                limit: maxFirstPage,
                salesCategory: selectedValue == "총판" ? 1 : selectedValue == "에이전시" ? 2 : selectedValue == "대리점" ? 3 : 0,
                salesValue: selectedText,
                searchCategory: selectedValue3 == "매장명" ? 1 : selectedValue3 == "TID" ? 2 : selectedValue3 == "승인번호" ? 3 : 0,
                searchValue: selectedText3,
                startDate: preDate,
                endDate: nextDate,
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                console.log(tmp.data)
                setListData(tmp?.data ?? [])
                setAmountResult(tmp?.data?.amountResult ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "70px", "textAlign": "left" },
        { "no": 17, "text": "정산일자", "width": "110px", "textAlign": "center" },
        // { "no": 2, "text": "MCODE", "width": "100px", "textAlign": "center" },
        { "no": 3, "text": "TID", "width": "110px", "textAlign": "center" },
        { "no": 4, "text": "총판", "width": "150px", "textAlign": "center" },
        { "no": 5, "text": "에이전시", "width": "150px", "textAlign": "center" },
        { "no": 6, "text": "대리점", "width": "150px", "textAlign": "center" },
        { "no": 7, "text": "가맹점명", "width": "150px", "textAlign": "center" },
        // { "no": 8, "text": "통신비", "width": "90px", "textAlign": "center" },
        // { "no": 9, "text": "가맹점 계좌번호", "width": "180px", "textAlign": "left" },
        // { "no": 10, "text": "구분", "width": "120px", "textAlign": "center" },
        { "no": 11, "text": "매출액", "width": "120px", "textAlign": "center" },
        { "no": 12, "text": "앤트", "width": "100px", "textAlign": "center" },
        { "no": 13, "text": "총판", "width": "100px", "textAlign": "center" },
        { "no": 14, "text": "에이전시", "width": "100px", "textAlign": "center" },
        { "no": 15, "text": "대리점", "width": "100px", "textAlign": "center" },
        { "no": 16, "text": "정산금액", "width": "120px", "textAlign": "center" },
    ];

    useEffect(() => {
        let tableBodyData = [];
        console.log(listData)
        if (listData) {
            let list = listData?.settlements ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                tableBodyData.push([
                    { "no": 1, "text": data.id, "width": "70px", "textAlign": "left" },
                    { "no": 17, "text": `${data.date}`, "width": "110px", "textAlign": "center" },
                    // { "no": 2, "text": data.franchiseeId, "width": "100px", "textAlign": "center" },
                    { "no": 3, "text": data.tid, "width": "110px", "textAlign": "left" },
                    { "no": 4, "text": data.tier1Name, "width": "150px", "textAlign": "left" },
                    { "no": 5, "text": data.tier2Name, "width": "150px", "textAlign": "left" },
                    { "no": 6, "text": data.tier3Name, "width": "150px", "textAlign": "left" },
                    { "no": 7, "text": data.franchiseeName, "width": "150px", "textAlign": "left" },
                    // { "no": 8, "text": "N", "width": "90px", "textAlign": "center" },
                    // { "no": 9, "text": "1002-000-000000", "width": "180px", "textAlign": "left" },
                    // { "no": 10, "text": "비사업자PG", "width": "120px", "textAlign": "center" },
                    { "no": 11, "text": changeNum(Number(data.totalTransactionAmount)) + "원", "width": "120px", "textAlign": "right" },
                    { "no": 12, "text": changeNum(Number(data.totalPgFeeAmount)) + "원", "width": "100px", "textAlign": "right" },
                    { "no": 13, "text": changeNum(Number(data.totalTier1FeeAmount)) + "원", "width": "100px", "textAlign": "right" },
                    { "no": 14, "text": changeNum(Number(data.totalTier2FeeAmount)) + "원", "width": "100px", "textAlign": "right" },
                    { "no": 15, "text": changeNum(Number(data.totalTier3FeeAmount)) + "원", "width": "100px", "textAlign": "right" },
                    { "no": 16, "text": changeNum(Number(data.totalNetAmount)) + "원", "width": "120px", "textAlign": "right" },
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData])

    const excelListBtn = async () => {
        await axios.get(process.env.REACT_APP_API + "/settlement/list/excel", {
            withCredentials: true,
            params: {
                salesCategory: selectedValue == "총판" ? 1 : selectedValue == "에이전시" ? 2 : selectedValue == "대리점" ? 3 : 0,
                salesValue: selectedText,
                searchCategory: selectedValue3 == "매장명" ? 1 : selectedValue3 == "TID" ? 2 : selectedValue3 == "승인번호" ? 3 : 0,
                searchValue: selectedText3,
                startDate: preDate,
                endDate: nextDate,
            }
        }).then((response) => {
            let tmp = response?.data
            if (tmp?.code < 2000) {
                excelListData(tmp?.data ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const excelListData = (exData) => {
        let arr = [];
        if (exData) {
            for (let i = 0; i < exData.length; i++) {
                let t = exData[i]

                arr.push({
                    "NO": i + 1,
                    "MCODE": t.id,
                    "TID": t.tid,
                    "총판": `${t.tier1Name}`,
                    "에이전시": t.tier2Name,
                    "대리점": t.tier3Name,
                    "가맹점명": t.franchiseeName,
                    "통신비": "N",
                    "구분": "비사업자PG",
                    "매출액": changeNum(Number(t.totalTransactionAmount)),
                    "앤트": changeNum(Number(t.totalPgFeeAmount)),
                    "총판수수료": changeNum(Number(t.totalTier1FeeAmount)),
                    "에이전시수수료": changeNum(Number(t.totalTier2FeeAmount)),
                    "대리점수수료": changeNum(Number(t.totalTier3FeeAmount)),
                    "가맹점": changeNum(Number(t.totalNetAmount)),
                    "정산일자": t.date,
                })
            }
        }
        Excel(1, arr)
    }

    const excelDepositBtn = async () => {
        if(!preDate) {
            alert("날짜 입력 필수");
            return;
        }
        await axios.get(process.env.REACT_APP_API + "/settlement/deposit/list", {
            withCredentials: true,
            params: {
                startDate: preDate,
                endDate: nextDate,
            }
        }).then((response) => {
            console.log(response);
            let tmp = response?.data
            if (tmp?.code < 2000) {
                console.log(tmp.data)
                excelDepositData(tmp?.data ?? [])
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const excelDepositData = (exData) => {
        let arr = []
        if (exData) {
            for (let i = 0; i < exData.length; i++) {
                let t = exData[i]

                arr.push({
                    "은행": t.bankName,
                    "계좌번호": t.accountNumber,
                    "금액": changeNum(Number(t.amount)),
                    "가맹점": t.franchiseeName,
                    "지급사": "PG사",
                })
            }
        }
        Excel(2, arr)
    }

    // 더보기 버튼
    const [moreBtn, setMoreBtn] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 640) setMoreBtn(true)
            else setMoreBtn(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            <div className="form_filter intro-y">
                <form id="tabulator-html-filter-form">

                    <SearchDate type={"기간"} inputRef={inputRef} setDateRange={setDateRange} />

                    {moreBtn && <SearchSelectText type={"영업자"} selectData={select1} handleSelectChange={handleSelectChange} selectedValue={selectedValue} handleSelectTextChange={handleSelectTextChange} selectedText={selectedText} />}

                    {moreBtn && <SearchSelectText type={"검색구분"} selectData={select4} handleSelectChange={handleSelectChange3} selectedValue={selectedValue3} handleSelectTextChange={handleSelectTextChange3} selectedText={selectedText3} />}

                    <SearchBtn type={"검색"} onClick={() => onSubmitSearch()} />
                </form>
                <div className="more_wrap">
                    <button type="button" onClick={() => setMoreBtn(e => !e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="chevron-down" data-lucide="chevron-down" className="lucide lucide-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg><span>더보기</span>
                    </button>
                </div>
            </div>
            <div className="intro-y box p-5 mt-5">
                <div className="tabulator_sorting_wrap">
                    <div className='flex gap-4'>
                        <DownloadBtn text={"엑셀 다운로드"} onClick={excelListBtn} />
                        <DownloadBtn text={"입금 엑셀"} onClick={excelDepositBtn} />
                    </div>
                    <CountSelect onChange={handleChange} />
                </div>
                <div className="tabulator_summary_wrap">
                    <SettlementAmount totalAmount={amountResult?.totalAmount ?? 0} settlementAmount={amountResult?.settlementAmount ?? 0} totalFee={amountResult?.totalFee ?? 0} />
                    <CountAmount tableBodyData={listData?.meta?.total ?? 0} />
                </div>

                <List tableHeaderData={tableHeaderData} tableBodyData={listData?.meta?.total ?? 0} filter_tableBodyData={combineData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />

                <input type="hidden" id="fromDate" />
                <input type="hidden" id="toDate" />
                <input type="hidden" id="date_to_date" />
            </div>

        </div>
    )
}

const Excel = (num, excelData) => {
    const excelFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelFileExtension = '.xlsx';
    const excelFileName = num == 1 ? `정산내역` : `입금내역`;

    const ws = XLSX.utils.aoa_to_sheet([
        num == 1
            ?
            [
                "NO", "MCODE", "TID", "총판", "에이전시", "대리점", "가맹점명", "통신비",
                "구분", "매출액", "앤트", "총판 수수료", "에이전시 수수료", "대리점 수수료", "가맹점", "정산일자"
            ]
            :
            [
                "은행", "계좌번호", "금액", "가맹점", "지급사"
            ]
    ]);
    excelData.map((data) => {
        XLSX.utils.sheet_add_aoa(
            ws,
            [
                num == 1
                    ?
                    [
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.NO },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.MCODE },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.TID },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.총판 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.에이전시 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.대리점 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.가맹점명 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.통신비 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.구분 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.매출액 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.앤트 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.총판수수료 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.에이전시수수료 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.대리점수수료 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.가맹점 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.정산일자 }
                    ]
                    :
                    [
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.은행 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.계좌번호 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.금액 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.가맹점 },
                        { t: 's', s: { alignment: { horizontal: 'center' } }, v: data.지급사 },
                    ]
            ],
            { origin: -1 }
        );
        ws['!cols'] = [
            num == 1
                ?
                [
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 }]
                :
                [
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                    { wpx: 100 },
                ]
        ]
        return false;
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelButter = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelFile = new Blob([excelButter], { type: excelFileType });
    FileSaver.saveAs(excelFile, excelFileName + excelFileExtension);
};

export default Settlements