import { configureStore, createSlice } from '@reduxjs/toolkit'

let login = createSlice({
    name: 'login',
    initialState: { value: false },
    reducers: {
        checkLogin(state, action) {
            state.value = action.payload
        }
    }
})

let Darkmode = createSlice({
    name: 'Darkmode',
    initialState: { value: false },
    reducers: {
        checkMode(state, action) {
            const objString = localStorage.getItem('token');
            if (objString != null) {
                const obj = JSON.parse(objString);
                const newobj = {
                    value: obj.value,
                    expire: obj.expire,
                    mode: action.payload ? "dark" : "light",
                }
                const newobjString = JSON.stringify(newobj);
                localStorage.setItem('token', newobjString)
            }
            state.value = action.payload
        }
    }
})

let HeaderTitle = createSlice({
    name: 'HeaderTitle',
    initialState: {
        mainTitle: "",
        subTitle: ""
    },
    reducers: {
        changeHeaderTitle(state, action) {
            return action.payload
        }
    }
})

let Current_Location = createSlice({
    name: 'Current_Location',
    initialState: { value: "" },
    reducers: {
        change_Current_Location(state, action) {
            state.value = action.payload
        }
    }
})

let Favorite = createSlice({
    name: 'Favorite',
    initialState: JSON.parse(localStorage.getItem('favorite_menu')) ??
        [
            { name: "대시보드", state: 0, link: "dashboard" },
            { name: "거래내역", state: 0, link: "paylist" },
            { name: "거래보류내역", state: 0, link: "setoffPaylist" },
            { name: "정산달력", state: 0, link: "calendar" },
            { name: "정산내역", state: 0, link: "settlements" },
            { name: "상계내역", state: 0, link: "setoffSettlements" },
            { name: "무실적가맹점", state: 0, link: "unsoldMerchant" },
            // { name: "거래별 정산이력", state: 0, link: "payDetailList" },
            // { name: "정산이력", state: 0, link: "settlementHis" },
            { name: "업체등록", state: 0, link: "excelData" },
            { name: "총판", state: 0, link: "distributor" },
            { name: "에이전시", state: 0, link: "agency" },
            { name: "대리점", state: 0, link: "franchise" },
            { name: "가맹점", state: 0, link: "submerchants" },
            { name: "관리자조회", state: 0, link: "admin" },
            { name: "공지사항", state: 0, link: "board" },
            // { name: "단말기 관리", state: 0, link: "product" },
            { name: "즐겨찾기 관리", state: 0, link: "favorite" }
        ],
    reducers: {
        change_Favorite(state, action) {
            localStorage.setItem('favorite_menu', JSON.stringify(action.payload));
            return action.payload
        }
    }
});

export let { checkLogin } = login.actions
export let { checkMode } = Darkmode.actions
export let { changeHeaderTitle } = HeaderTitle.actions
export let { change_Current_Location } = Current_Location.actions
export let { change_Favorite } = Favorite.actions

export default configureStore({
    reducer: {
        login: login.reducer,
        Darkmode: Darkmode.reducer,
        HeaderTitle: HeaderTitle.reducer,
        Current_Location: Current_Location.reducer,
        Favorite: Favorite.reducer,
    }
}) 