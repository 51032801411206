import { useDispatch } from 'react-redux';
import './css/app.css'
import './css/common.css'
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { change_Current_Location, changeHeaderTitle } from '../store';
import axios from 'axios';
import { MessageModal } from './component/modal/modal';

import { CountAmount, CountSelect, DownloadBtn, TotalAmount } from './component/btn/btn';
import List from './component/list/list';
import { LoadingModal } from './component/modal/modal';
import { useNavigate } from 'react-router-dom';


function ExcelData() {
    let dispatch = useDispatch()
    let navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [maxFirstPage, setMaxFirstPage] = useState(10)
    const [message, setMessage] = useState("")

    const [listData, setListData] = useState([])
    const [combineData, setCombineData] = useState([])
    const [clickData, setClickData] = useState([])
    const [state, setState] = useState(0)
    const [updateModal, setUpdateModal] = useState(false)

    useEffect(() => {
        dispatch(change_Current_Location("업체등록"))
        dispatch(changeHeaderTitle({
            mainTitle: "매장관리",
            subTitle: "업체등록"
        }))

        setTimeout(() => {
            setLoading(false)
        }, 500)
    }, [])


    const handleChangeAgent = (e) => {
        const { name, value, type, checked, files } = e.target;

        if (type === 'file') {
            onSubmitAgent(files)
            setLoading(true)

            setTimeout(() => {
                setState(1)
                ListDataAgent()
                setLoading(false)
            }, 500)
        }
    };

    const handleChangeFranchisee = (e) => {
        const { name, value, type, checked, files } = e.target;

        if (type === 'file') {
            onSubmitFranchisee(files)
            setLoading(true)

            setTimeout(() => {
                setState(2)
                ListDataFranchisee()
                setLoading(false)
            }, 500)
        }
    };

    const onSubmitAgent = async (files) => {
        await axios.post(process.env.REACT_APP_API + "/sales/upload", {
            file: files[0] ?? ""
        }, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
        }).then((response) => {
            console.log(response.data)
            let tmp = response?.data
            if (tmp?.code < 2000) {

            }
            else {
                setMessage({ msg: "등록에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const onSubmitFranchisee = async (files) => {
        await axios.post(process.env.REACT_APP_API + "/franchisee/upload", {
            file: files[0] ?? ""
        }, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
        }).then((response) => {
            console.log(response.data)
            let tmp = response?.data
            if (tmp?.code < 2000) {

            }
            else {
                setMessage({ msg: "등록에 실패했습니다." })
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const ListDataAgent = async () => {
        await axios.get(process.env.REACT_APP_API + "/sales/temp/list", {
            withCredentials: true,
            params: {
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const ListDataFranchisee = async () => {
        await axios.get(process.env.REACT_APP_API + "/franchisee/temp/list", {
            withCredentials: true,
            params: {
            }
        }).then((response) => {
            let tmp = response?.data
            console.log(tmp)
            if (tmp?.code < 2000) {
                setListData(tmp?.data ?? [])
            }
            else if (tmp?.code === 9999) {
                navigate('/')
            }
        }).catch(error => {
            console.error(error);
        });
    }

    const tableHeaderData = [
        { "no": 1, "text": "NO", "width": "70px", "textAlign": "left" },
        { "no": 2, "text": "이름", "width": "100px", "textAlign": "center" },
        { "no": 3, "text": "TID", "width": "110px", "textAlign": "left" },
        { "no": 4, "text": "구분", "width": "100px", "textAlign": "left" },
        { "no": 5, "text": "주민등록번호", "width": "180px", "textAlign": "center" },
        { "no": 6, "text": "사업자구분", "width": "130px", "textAlign": "center" },
        { "no": 7, "text": "핸드폰", "width": "180px", "textAlign": "center" },
        { "no": 8, "text": "이메일", "width": "180px", "textAlign": "center" },
        { "no": 9, "text": "은행명", "width": "130px", "textAlign": "right" },
        { "no": 10, "text": "계좌번호", "width": "180px", "textAlign": "center" },
        { "no": 11, "text": "계좌주", "width": "100px", "textAlign": "center" },
        // { "no": 12, "text": "상태", "width": "300px", "textAlign": "center" }
    ];

    useEffect(() => {
        let tableBodyData = [];
        if (listData) {
            let list = listData ?? []
            for (let i = 0; i < list.length; i++) {
                let data = list[i]

                tableBodyData.push([
                    { "no": 1, "text": maxFirstPage * (page - 1) + i + 1, "width": "70px", "textAlign": "left" },
                    { "no": 2, "text": (<div className='pointer' onClick={() => { onClickUpdate(data) }}>{data.representativeName}</div>), "width": "100px", "textAlign": "center" },
                    { "no": 3, "text": data.registrationNumber, "width": "110px", "textAlign": "left" },
                    { "no": 4, "text": data.level == 1 ? "총판" : data.level == 2 ? "에이전시" : "대리점", "width": "100px", "textAlign": "left" },
                    { "no": 5, "text": data.state == 0 ? "사업자" : "비사업자", "width": "130px", "textAlign": "center" },
                    { "no": 6, "text": data.accountHolder, "width": "180px", "textAlign": "center" },
                    { "no": 7, "text": data?.phone1 ?? "" + data?.phone2 ?? "", "width": "180px", "textAlign": "center" },
                    { "no": 8, "text": data.email, "width": "180px", "textAlign": "center" },
                    { "no": 9, "text": data.bankName, "width": "130px", "textAlign": "right" },
                    { "no": 10, "text": data.accountNumber, "width": "180px", "textAlign": "center" },
                    { "no": 11, "text": data.accountHolder, "width": "100px", "textAlign": "center" },
                    // { "no": 12, "text": data.status, "width": "300px", "textAlign": "center" }
                ])
            }
            setCombineData(tableBodyData)
        }
    }, [listData])

    // 한 페이지당 보여지는 개수만큼 배열 자르기
    let filter_tableBodyData = combineData.slice((page - 1) * maxFirstPage, (page) * maxFirstPage)

    const onClickUpdate = (data) => {
        if (state == 1) {
            UpdateAgent(data)
        }
        else if (state == 2) {
            UpdateFranchisee(data)
        }
    }

    const UpdateAgent = async (data) => {
        if (window.confirm("활성화 하시겠습니까?")) {
            await axios.post(process.env.REACT_APP_API + "/sales/temp/update", {
                id: Number(data.id)
            }, {
                withCredentials: true,
            }).then((response) => {
                let tmp = response?.data
                console.log(tmp)
                if (tmp?.code < 2000) {
                    ListDataAgent()
                }
                else if (tmp?.code === 9999) {
                    navigate('/')
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const UpdateFranchisee = async (data) => {
        if (window.confirm("활성화 하시겠습니까?")) {
            await axios.post(process.env.REACT_APP_API + "/franchisee/temp/update", {
                id: Number(data.id)
            }, {
                withCredentials: true,
            }).then((response) => {
                let tmp = response?.data
                console.log(tmp)
                if (tmp?.code < 2000) {
                    ListDataFranchisee()
                }
                else if (tmp?.code === 9999) {
                    navigate('/')
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }



    return (
        <div className="content" id="contents">
            {loading ? <LoadingModal /> : ""}
            {updateModal ? <UpdateModal /> : ""}
            <div className="intro-y box p-5 mt-5">
                <div className="intro-y box p-3">
                    <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
                        <div className="sm:flex items-center sm:mr-4">
                            <label className="btn btn-outline-secondary w-full sm:w-auto mr-2 agency_bt_sty btn_common" for="agency_excel">에이전시 업로드</label>
                            <input style={{ display: "none" }} className="btn btn-outline-secondary w-full sm:w-auto excel_upload_btn" id="agency_excel" aria-expanded="true" type="file" name="agencyExcel" onChange={handleChangeAgent} accept=".xlsx, .xls" />
                        </div>
                        <div className="sm:flex items-center sm:mr-4">
                            <label className="btn btn-outline-secondary w-full sm:w-auto mr-2 merchant_bt_sty btn_common" for="merchant_excel">가맹점 업로드</label>
                            <input style={{ display: "none" }} className="btn btn-outline-secondary w-full sm:w-auto excel_upload_btn" id="merchant_excel" aria-expanded="true" type="file" name="merchantExcel" onChange={handleChangeFranchisee} accept=".xlsx, .xls" />
                        </div>
                        <div className="sm:flex items-center sm:mr-4">
                            <label className="" for="addCnt">등록된 매장 수 : </label>
                            <input style={{ border: "0px" }} className="btn btn-outline-secondary" data-id="addCnt" id="addCnt" aria-expanded="true" value="0건" type="text" readonly="" />
                            <label className="" for="updateCnt">수정된 매장 수 : </label>
                            <input style={{ border: "0px" }} className="btn btn-outline-secondary" data-id="updateCnt" id="updateCnt" aria-expanded="true" value="0건" type="text" readonly="" />
                            <label className="" for="failCnt">실패한 매장 수 : </label>
                            <input style={{ border: "0px" }} className="btn btn-outline-secondary" data-id="last_page" id="failCnt" aria-expanded="true" value="0건" type="text" readonly="" />
                        </div>
                    </div>
                </div>
                <div className="intro-y box p-5 mt-5">
                    <h2 style={{ fontSize: "1.2rem", paddingTop: "1rem" }} className="merchant_list_title">미등록 {state == 1 ? "에이전시" : state == 2 ? "가맹점" : ""}내역</h2>

                    <List tableHeaderData={tableHeaderData} tableBodyData={combineData} filter_tableBodyData={filter_tableBodyData} page={page} setPage={setPage} maxFirstPage={maxFirstPage} />
                </div>
            </div>
        </div>
    )
}

function UpdateModal() {
    return (
        <div>

        </div>
    )
}

export default ExcelData;