import { useDispatch, useSelector } from 'react-redux';
import '../css/app.css'
import '../css/common.css'
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom"
import axios from 'axios';
import { MessageModal } from '../component/modal/modal';
import { change_Favorite } from '../../store';
import {
    LayoutDashboard, ChevronDown, NotebookPen, CircleDollarSign, Building2, FilePenLine, CalendarCheck, FileMinus, Users, UserRoundX, Tally1,
    Layers, FileText, FolderPen, UserPlus, Globe, Crown, LocateFixed, Network, UserSearch, Volume2, MonitorSmartphone, PhoneCall, Star, CalendarDays
} from 'lucide-react';

function Header() {
    let location = useLocation();
    let navigate = useNavigate();
    let dispatch = useDispatch()
    let HeaderTitle = useSelector((state) => { return state.HeaderTitle })
    let Favorite = useSelector((state) => { return state.Favorite })

    const [Favorite_List, setFavorite_List] = useState(Favorite?.filter(item => item.state == 1) ?? [])

    useEffect(() => {
        setFavorite_List(Favorite.filter(item => item.state == 1))
    }, [Favorite])

    const [hamBtn, setHamBnt] = useState(false)
    const [btn, setBtn] = useState([false, false, false, false, false])
    const [time, setTime] = useState("")
    const [resetTime, setResetTime] = useState(false)
    const [message, setMessage] = useState("")

    const onClickBtn = (e) => {
        let arr = [false, false, false, false, false]
        for (let i = 0; i < arr.length; i++) {
            if (e === i) {
                if (btn[i]) arr[i] = false
                else arr[i] = true
            }
            else arr[i] = false
        }
        setBtn(arr)
    }

    useEffect(() => {
        let t = 60 * 60 * 24; // 24시간을 초 단위로 변환

        const intervalId = setInterval(() => {
            t -= 1;
            const hours = Math.floor(t / 3600); // 시 계산
            const minutes = Math.floor((t % 3600) / 60); // 분 계산
            const seconds = t % 60; // 초 계산

            // 시간, 분, 초를 두 자리 숫자로 변환하여 출력
            setTime(
                `${String(hours).padStart(2, '0')} : ${String(minutes).padStart(2, '0')} : ${String(seconds).padStart(2, '0')}`
            );

            // 시간이 0이 되면 카운트다운을 멈춤
            if (t <= 0) {
                clearInterval(intervalId);
            }
        }, 1000);

        // 컴포넌트가 언마운트될 때 인터벌을 정리
        return () => clearInterval(intervalId);
    }, [resetTime]);

    const onSubmit = async () => {
        if (window.confirm("로그아웃을 하시겠습니까?")) {
            await axios.post(process.env.REACT_APP_API + "/admin/logout", {
            }, {
                withCredentials: true,
            }).then((response) => {
                let tmp = response?.data
                console.log(tmp)
                if (tmp?.code < 2000) {
                    navigate('/')
                }
                else if (tmp?.code === 9999) {
                    navigate('/')
                }
                else {
                    setMessage({ msg: "로그아웃에 실패했습니다." })
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }

    const ResponsiveImage = ({ smallSrc, largeSrc, alt, breakpoint = 768 }) => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            const handleResize = () => setWindowWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        const imageSrc = windowWidth < breakpoint ? smallSrc : largeSrc;

        return <img src={imageSrc} alt={alt} className="w-32" />;
    };

    return (
        <div style={{ display: location.pathname == '/' ? "none" : "" }}>
            <MessageModal message={message} />
            <div className={`mobile-menu md:hidden  ${hamBtn ? "mobile-menu--active" : ""}`}>
                <div className="mobile-menu-bar">
                    <Link to={`/dashboard`} className="main_logo flex mr-auto gnb-link">
                        {/* <img alt="East Commerce Logo" className="w-32" src="/img/antpay01.jpg" /> */}
                        <ResponsiveImage
                            smallSrc="/img/ant-simple.png"
                            largeSrc="/img/antpay01.jpg"
                            alt="East Commerce Logo"
                            breakpoint={768}
                        />
                    </Link>
                    <ul className="header_gnb">
                        <li>
                            <Link to={`/paylist`} className="gnb-link" data-target="paylist.html">조회</Link>
                        </li>
                        <li>
                            <Link to={`/board`} className="gnb-link" data-target="board.html">공지</Link>
                        </li>
                        <li>
                            <Link to={`/settlements`} className="gnb-link" data-target="settlements.html">정산</Link>
                        </li>
                    </ul>
                    <Link href="javascript:;" className={`mobile-menu-toggler`} onClick={() => setHamBnt(e => !e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="bar-chart-2" data-lucide="bar-chart-2" className="lucide lucide-bar-chart-2 w-8 h-8 text-white transform -rotate-90">
                            <line x1="18" y1="20" x2="18" y2="10"></line>
                            <line x1="12" y1="20" x2="12" y2="4"></line>
                            <line x1="6" y1="20" x2="6" y2="14"></line>
                        </svg>
                    </Link>
                </div>
                <div className="scrollable">
                    <div className="menu_wrap">
                        <div className="user_info_wrap">
                            <div className="user_detail">
                                <span className="user_authority">관리자</span>
                                <span className="user_organization">ant</span>
                            </div>
                            <div className="user_greeting">
                                <p className="user_name">ANTPAY</p>
                                <p>님 반갑습니다.</p>
                            </div>
                        </div>
                        <div className="login_session_wrap menu__title">
                            <div className="timer_wrap">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="clock-3" data-lucide="clock-3" className="lucide lucide-clock-3">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <polyline points="12 6 12 12 16.5 12"></polyline>
                                </svg>
                                <span className="timer">23 : 58 : 41</span>
                            </div>
                            <button type="button" className="logout_reset_btn">
                                로그인 연장<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="refresh-cw" data-lucide="refresh-cw" className="lucide lucide-refresh-cw">
                                    <path d="M21 2v6h-6"></path>
                                    <path d="M3 12a9 9 0 0 1 15-6.7L21 8"></path>
                                    <path d="M3 22v-6h6"></path>
                                    <path d="M21 12a9 9 0 0 1-15 6.7L3 16"></path>
                                </svg>
                            </button>
                        </div>
                        <hr className="separation_line" />
                        <ul className="scrollable__content py-2">
                            <li>
                                <Link to={`/dashboard`} className="menu side_gnb" onClick={() => setHamBnt(false)}>
                                    <div className="menu__icon">
                                        <LayoutDashboard />
                                    </div>
                                    <div className="menu__title">
                                        대시보드
                                        <i className="menu__sub-icon"></i>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link className="menu" onClick={() => onClickBtn(0)}>
                                    <div className="menu__icon">
                                        <NotebookPen />
                                    </div>
                                    <div className="menu__title">
                                        거래조회
                                        <i className={`menu__sub-icon ${btn[0] ? "transform rotate-180" : ""}`}></i>
                                    </div>
                                </Link>
                                {
                                    btn[0]
                                        ?
                                        <ul className="depth_2">
                                            <li>
                                                <Link to={`/paylist`} className="menu" data-id="paylist" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">거래내역</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/setoffPaylist`} className="menu" data-id="paylist" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">거래보류내역</div>
                                                </Link>
                                            </li>
                                        </ul>
                                        :
                                        ""
                                }
                            </li>
                            <li>
                                <Link className="menu" onClick={() => onClickBtn(1)}>
                                    <div className="menu__icon">
                                        <CircleDollarSign />
                                    </div>
                                    <div className="menu__title">
                                        정산관리
                                        <i className={`menu__sub-icon ${btn[1] ? "transform rotate-180" : ""}`}></i>
                                    </div>
                                </Link>
                                {
                                    btn[1]
                                        ?
                                        <ul className="depth_2">
                                            <li>
                                                <Link to={`/calendar`} className="menu side_gnb" data-id="calendar" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">정산달력</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/settlements`} className="menu side_gnb" data-id="calendar" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">정산내역</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/setoffSettlements`} className="menu side_gnb" data-id="coins" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">상계내역</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/payDetailList`} className="menu side_gnb" data-id="franchise" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">거래별 정산이력</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/unsoldMerchant`} className="menu side_gnb" data-id="unsoldMerchant" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">무실적가맹점</div>
                                                </Link>
                                            </li>
                                        </ul>
                                        :
                                        ""
                                }
                            </li>
                            {/* <li>
                                <Link className="menu" onClick={() => onClickBtn(2)}>
                                    <div className="menu__icon">
                                        <FolderPen />
                                    </div>
                                    <div className="menu__title">
                                        이력관리
                                        <i className={`menu__sub-icon ${btn[2] ? "transform rotate-180" : ""}`}></i>
                                    </div>
                                </Link>
                                {
                                    btn[2]
                                        ?
                                        <ul className="depth_2">
                                            <li>
                                                <Link to={`/settlementHis`} className="menu side_gnb" data-id="franchise" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">정산이력</div>
                                                </Link>
                                            </li>
                                        </ul>
                                        :
                                        ""
                                }
                            </li> */}

                            <li>
                                <Link className="menu" onClick={() => onClickBtn(3)}>
                                    <div className="menu__icon">
                                        <Building2 />
                                    </div>
                                    <div className="menu__title">
                                        매장관리
                                        <i className={`menu__sub-icon ${btn[3] ? "transform rotate-180" : ""}`}></i>
                                    </div>
                                </Link>
                                {
                                    btn[3]
                                        ?
                                        <ul className="depth_2">
                                            <li>
                                                <Link to={`/excelData`} className="menu side_gnb" data-id="vrAcctAdd" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">업체등록</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/distributor`} className="menu side_gnb" data-id="vrAcctAdd" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">총판</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/agency`} className="menu side_gnb" data-id="vrAcctAdd" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">에이전시</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/franchise`} className="menu side_gnb" data-id="vrAcctAdd" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">대리점</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/submerchants`} className="menu side_gnb" data-id="vrAcctAdd" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">가맹점</div>
                                                </Link>
                                            </li>
                                        </ul>
                                        :
                                        ""
                                }
                            </li>
                            <li>
                                <Link className="menu" onClick={() => onClickBtn(4)}>
                                    <div className="menu__icon">
                                        <Users />
                                    </div>
                                    <div className="menu__title">
                                        관리자 메뉴
                                        <i className={`menu__sub-icon ${btn[4] ? "transform rotate-180" : ""}`}></i>
                                    </div>
                                </Link>
                                {
                                    btn[4]
                                        ?
                                        <ul className="depth_2">
                                            <li>
                                                <Link to={`/admin`} className="menu side_gnb" data-id="admin" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">관리자 조회</div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/board`} className="menu side_gnb" data-id="board" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">공지사항</div>
                                                </Link>
                                            </li>
                                            {/* <li>
                                                <Link to={`/product`} className="menu side_gnb" data-id="board" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">단말기 관리</div>
                                                </Link>
                                            </li> */}
                                            <li>
                                                <Link to={`/favorite`} className="menu side_gnb" data-id="board" onClick={() => setHamBnt(false)}>
                                                    <div className="menu__title">즐겨찾기 관리</div>
                                                </Link>
                                            </li>
                                        </ul>
                                        :
                                        ""
                                }
                            </li>
                        </ul>
                    </div>
                    <div className="logout-btn">
                        <Link id="logout-admin" onClick={() => onSubmit()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="power" data-lucide="power" className="lucide lucide-power">
                                <path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path>
                                <line x1="12" y1="2" x2="12" y2="12"></line>
                            </svg>
                            <span>로그아웃</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="top-bar-boxed h-[70px] md:h-[65px] z-[51] border-b border-white/[0.08] mt-12 md:mt-0 -mx-3 sm:-mx-8 md:-mx-0 px-3 md:border-b-0 relative md:fixed md:inset-x-0 md:top-0 sm:px-10 md:px-10 md:pt-15 md:bg-gradient-to-b md:from-slate-100 md:to-transparent dark:md:from-darkmode-700">
                <div className="header_wrap h-full flex items-center">
                    <div className="header_left">
                        <Link to={`/dashboard`} className="logo -intro-x hidden md:flex xl:w-[180px] block" style={{ left: "5px" }}>
                            <img alt="PayK" className="logo__image w-32" src="/img/antpay01.jpg" />
                        </Link>
                        <nav aria-label="breadcrumb" className="-intro-x h-[45px] mr-auto">
                            <ol className="breadcrumb breadcrumb-light">
                                <li className="breadcrumb-item pg_title" id="pg_title" value="">{HeaderTitle?.mainTitle ?? ""}</li>
                                <li className="breadcrumb-item active" id="pg_sub_title" aria-current="page">{HeaderTitle?.subTitle ?? ""}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="header_right intro-x">
                        <div className='header-favorite'>
                            {
                                Favorite_List.map(function (a, i) {
                                    return (
                                        <Link to={`/${a.link}`} className='header-favorite-item'>
                                            <div><Star className='icon-start' /></div>
                                            <div style={{ paddingTop: "5px" }}>{a.name}</div>
                                        </Link>
                                    )
                                })
                            }
                            <div><Tally1 style={{ color: "#fff" }} /></div>
                        </div>
                        <div className="user_info_wrap">
                            <div className="user_name">
                                <p>ANTPAY</p>
                                <span>관리자</span>
                            </div>
                            <p className="user_organization">ant</p>
                        </div>
                        <div className="login_session_box">
                            <div className="timer_wrap">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" icon-name="clock-3" data-lucide="clock-3" className="lucide lucide-clock-3">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <polyline points="12 6 12 12 16.5 12"></polyline>
                                </svg>
                                <span className="timer">{time}</span>
                            </div>
                            <div className="button_wrap">
                                <button type="button" className="logout_reset_btn" onClick={() => setResetTime(e => !e)}>연장</button>
                                <button id="logout-admin-pc" onClick={() => onSubmit()}>로그아웃</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;